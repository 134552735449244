import { findCountryLocales } from 'iso-lang-codes'
import { code } from 'currency-codes'

const getLocaleFromISO31661alpha2 = (country: string) => {
  const locales = findCountryLocales(country)

  return locales[0] || 'en-US'
}

const formatCurrency = (country: string, value: number | string, currency: string | undefined) => {
  const currencyWithFallback = currency || 'EUR'
  const isCurrencyValid = code(currencyWithFallback) !== undefined
  const parsedValue = Number(value)

  if (!isCurrencyValid || isNaN(parsedValue)) {
    return `${currencyWithFallback} ${value.toString().trim()}`
  }

  const locale = getLocaleFromISO31661alpha2(country)

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: (currency || 'EUR').toUpperCase(),
    currencyDisplay: 'code',
    numberingSystem: 'latn',
  } as any).format(Number(value)) // any because ancient webpack complains about numberingSystem, but it has wideline support. TODO: remove when upgrading to vite
}

export const localesUtils = {
  getLocaleFromISO31661alpha2,
  formatCurrency,
}
